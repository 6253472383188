// band
.band {
  display: flex;
  align-items: center;
  width: 40%;

  img {
    width: 100%;
  }

  > .-text {
    position: absolute;
    color: white;

    h3 {
      font-size: 120%;
      line-height: 0;
      margin: 0;
      font-weight: 300;
      padding-left: 1rem;
    }
  }
}
@media (min-width: 0) and (max-width: 991px) {
  .band {
    width: 100%;
  }
}
