@import "../variable.scss";

.admin {
    min-height: 100vh;
    padding: 60px;
    background-color: $white-color;
    .upload-file {
        margin-top: 60px;
        margin-bottom: 20px;
        .carousel .slider-wrapper {
            width: 500px;
            height: 500px;
            > .slider > .slide > div {
                height: 100%;
            }
        }
        .-list {
            display: flex;
            .-picture {
                position: relative;
                display: block;
                width: 100px;
                height: 100px;
                margin: 10px;
                .-img {
                    width: 100%;
                    height: 100%;
                    overflow: hidden;
                    border-radius: 10px;
                    border: 1px solid $blue-color;
                }
                .fa-times-circle {
                    position: absolute;
                    top: -5px;
                    right: -5px;
                    font-size: 1.2rem;
                    color: $red-color;
                    cursor: pointer;
                    background-color: $white-color;
                }
            }
            .custom-upload {
                [type="file"] {
                    display: none;
                }
                label {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100px;
                    height: 100px;
                    font-size: 3rem;
                    color: $blue-color;
                    margin: 10px;
                    border-radius: 10px;
                    cursor: pointer;
                    border: 1px dashed $blue-color;
                }
            }
        }
    }

    .custom-editor {
        margin-top: 20px;
        .rdw-editor-main {
            min-height: 200px;
        }
    }

    .custom-carousel {
        border: 1px solid $red-color;
        .fas {
            color: $red-color;
        }
        .carousel-item {
            min-height: 520px;
            background-color: #000000;
        }
    }
    .no-banner {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 520px;
        font-size: 3rem;
        color: #777777;
        border: 1px solid $red-color;
    }
}

.admin-navbar {
    position: relative;
    display: flex;
    justify-content: space-between;
    color: #fff;
    overflow: hidden;
    background-color: #000000;
    > .-title,
    > .-logout {
        position: relative;
        padding: 20px 30px;
        z-index: 1;

        &::after {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            min-width: 400px;
            z-index: -1;
            background-color: $red-color;
        }
    }
    > .-title {
        font-size: 1.2rem;
        font-weight: 500;
        &::after {
            left: -35px;
            transform: skewX(145deg);
        }
    }
    > .-logout {
        position: relative;
        color: #fff;
        &::after {
            right: -35px;
            transform: skewX(45deg);
        }
    }
}
