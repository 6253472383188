@import '~styles/variable.scss';

.carousel {
  .control-dots {
    display: none;
  }
  .carousel-status {
    display: none;
  }
  .thumbs {
    display: flex;
    justify-content: center;
  }
  .thumb {
    min-width: 33%;
    margin: 0;
    transition: none !important;
    border: unset !important;

    &:focus {
      border: unset !important;
    }
    &.selected {
      border: unset !important;
      border-bottom: 5px solid $blue-color !important;
    }
  }
  .slide {
    background-color: unset !important;

    img {
      max-width: 70%;
    }
  }
}
