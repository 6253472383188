//
@import './variable.scss';
@import url('https://fonts.googleapis.com/css2?family=Prompt:wght@100;200;300;400;500;600;700&display=swap');
body {
  background-color: $body-background;
  font-family: 'Prompt', sans-serif;
  font-weight: 400;
}

// image
img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

// focus
*:focus {
  outline: unset;
}

// overide react-draft-wysiwyg.css
.rdw-option-wrapper {
  min-width: 30px !important;
  height: 30px !important;
  border: 1px solid #ccc !important;
}

.rdw-option-active {
  background-color: #ccc;
}

.rdw-editor-toolbar {
  margin-bottom: 0;
  border: 1px solid #cccccc;
  border-bottom: unset;
}

.rdw-editor-main {
  border: 1px solid #cccccc;
}

.g-cursor-pointer {
  cursor: pointer !important;
}

// html draft
.g-draft {
  p {
    min-height: 1rem;
  }
}

// content title
.g-title {
  position: relative;
  display: block;
  width: fit-content;
  color: $white-color;
  text-align: center;
  z-index: 10;
  padding: 6px 30px 12px 30px;
  &::after,
  &::before {
    content: '';
    position: absolute;
    display: block;
    transform: skewX(160deg);
  }
  &::before {
    top: 0;
    left: 0;
    right: 4px;
    bottom: 6px;
    z-index: -1;
    background-color: $red-color;
  }
  &::after {
    top: 6px;
    left: 4px;
    right: 0;
    bottom: 0;
    z-index: -2;
    background-color: $black-color;
  }
}
.g-title-center {
  position: relative;
  display: flex;
  justify-content: center;
  z-index: 1;
  &::after {
    content: '';
    position: absolute;
    top: calc(50% - 1rem);
    width: 100%;
    height: 2rem;
    z-index: -3;
    background-color: $black-color;
  }
  > .-bar {
    position: relative;
    display: block;
    width: 85%;
    max-width: 500px;
    color: $white-color;
    text-align: center;
    padding: 1rem 0.735rem;
    &::after,
    &::before {
      content: '';
      position: absolute;
      display: block;
      transform: skewX(160deg);
    }
    &::after {
      top: 0.5rem;
      bottom: 0.5rem;
      right: 0;
      left: 0;
      z-index: -2;
      background-color: $blue-color;
    }
    &::before {
      top: 0;
      bottom: 0;
      right: 10%;
      left: 10%;
      z-index: -1;
      background-color: $red-color;
    }
  }
}
.g-title-left {
  position: relative;
  display: block;
  width: fit-content;
  min-width: 280px;
  color: $white-color;
  margin-right: 25px;
  padding: 0.5rem 50px 0.5rem 0.5rem;
  background-color: $red-color;
  &::after,
  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    display: block;
    width: 15px;
    transform: skewX(160deg);
    border-left: 2px solid $white-color;
    border-right: 2px solid $white-color;
  }
  &::after {
    right: -15px;
    background-color: $blue-color;
  }
  &::before {
    right: 0;
    background-color: $black-color;
  }
  &.-mw-400 {
    min-width: 400px;
  }
}

.g-title-right {
  position: relative;
  display: block;
  width: fit-content;
  min-width: 280px;
  color: $white-color;
  text-align: right;
  margin-left: 25px;
  padding: 0.5rem 0.5rem 0.5rem 50px;
  background-color: $red-color;
  &::after,
  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    display: block;
    width: 15px;
    transform: skewX(160deg);
    border-left: 2px solid $white-color;
    border-right: 2px solid $white-color;
  }
  &::after {
    left: -15px;
    background-color: $blue-color;
  }
  &::before {
    left: 0;
    background-color: $black-color;
  }
  &.-mw-400 {
    min-width: 400px;
  }
}

// color
.g-color {
  &-white {
    color: $white-color !important;
  }
}

// button
.btn {
  width: -webkit-fill-available;
  &-blue {
    color: $white-color;
    border-radius: 0;
    border: 2px solid $blue-color;
    background-color: $blue-color;
    &:hover {
      color: $white-color;
      filter: brightness(115%);
    }
  }
  &-white {
    color: $blue-color;
    border-radius: 0;
    border: 2px solid $blue-color;
    background-color: $white-color;
    &:hover {
      color: $white-color;
      background-color: $blue-color;
    }
  }
  &-red {
    color: $white-color;
    border-radius: 0;
    border: 2px solid $red-color;
    background-color: $red-color;
    &:hover {
      color: $red-color;
      background-color: $white-color;
      border: 2px solid $red-color;
    }
  }
}

// checkbox
.g-custom-checkbox,
.g-custom-radio {
  [type='checkbox'],
  [type='radio'] {
    // ลบ radio ตัวเดิม
    &:checked,
    &:not(:checked) {
      display: none;
      // เพิ่ม radio ใหม่
      & + label {
        position: relative;
        display: block;
        width: fit-content;
        cursor: pointer;
        padding-left: 2rem;

        // checked
        &::after {
          content: '';
          position: absolute;
          display: block;
          left: 0.245rem;
          top: 0.3rem;
          width: 0.795rem;
          height: 0.475rem;
          border-left: 2.5px solid $white-color;
          border-bottom: 2.5px solid $white-color;
          transform: scale(1) rotate(-45deg) !important;
        }
        &::before {
          content: '';
          position: absolute;
          left: 0;
          display: block;
          width: 1.4rem;
          height: 1.4rem;
          border-radius: 2px;
          -webkit-transition: all 0.2s ease;
          transition: all 0.2s ease;
        }
      }
    }
    &:checked + label::before {
      background: $blue-color;
      border: 1px solid $blue-color;
    }
    &:not(:checked) + label::before {
      background: $white-color;
      border: 1px solid $gray-color;
    }
  }
}

.g-custom-radio [type='radio'] {
  &:checked,
  &:not(:checked) {
    & + label::before {
      border-radius: 50%;
    }
  }
}

//Meen

// container with magin
.container-global {
  display: flex;
  justify-content: center;
  background-color: $white-color;
  flex-wrap: wrap;

  > .sub {
    width: 70%;

    .breadcrumb {
      background-color: unset;
      font-weight: 200;
      margin: 0;

      &-item {
        a {
          color: $link-disable;
        }

        + .breadcrumb-item {
          &::before {
            content: '>';
          }
        }

        &.active {
          color: $link-enable;
        }
      }
    }
  }

  > .footer-sub {
    background-color: $light-gray;
    display: flex;
    justify-content: center;

    > .-panel {
      width: 70%;
      margin: 2em 0;
    }
  }
}

// card
.card-panel {
  margin: 2rem 0;
  .card-deck {
    justify-content: right;
  }
  .btn {
    position: absolute;
    right: 0;
    border-radius: unset;
    background-color: $link-enable;
    border-color: $link-enable;
    color: $white-color;
    font-weight: 300;
    max-width: 6em;
  }

  .lg-card {
    .btn {
      bottom: 11.75em;
    }
    .card {
      &-text {
        height: 6em;
        color: $light-gray;
        font-weight: 100;
        -webkit-line-clamp: 6;
      }
    }
  }

  .sm-card {
    .btn {
      bottom: 5.5em;
    }
    .card {
      &-text {
        height: 3em;
        font-weight: 300;
        -webkit-line-clamp: 2;
      }
    }
  }

  .sm-card,
  .lg-card {
    margin-bottom: 1.75rem;
    border-radius: 0;
    border: 0;
    min-width: 30%;
    flex: 1;
    flex-grow: inherit;
    margin-left: 19px;
    background-clip: unset;
    /* Tablet desktop :768px. */
    @media (min-width: 768px) and (max-width: 1024px) {
      min-width: 45%;
      margin-left: 10px;
    }

    .card {
      &-body {
        padding: 1.25em;
      }

      &-title {
        height: 2em;
        font-weight: 300;
      }

      &-text {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
      }

      &-img {
        &-top {
          height: 25vh;
          object-fit: cover;
          object-position: center;
          border-radius: 0;
        }
      }
    }
  }
}

//pagination
.pagination-panel {
  background-color: $light-gray;
  margin: 2em 0;
  display: flex;
  color: $dark-gray;

  > .-pageno {
    padding: 1em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    span {
      font-weight: 100;
    }

    .pagination {
      margin: 0;

      .page-item {
        .page-link {
          margin: 2px;
          color: $black-color;
        }

        &:last-child,
        &:first-child {
          .page-link {
            border-radius: unset;
          }
        }

        &.active {
          .page-link {
            color: $white-color;
            background-color: $black-color;
            border-color: $black-color;
          }
        }
      }
    }
  }
}

// overide react-bs-table
.react-bs-table-container > .react-bs-table .react-bs-container-header .table {
  margin-bottom: 0;
  thead th {
    border-bottom: 0;
    background-color: $light-gray-color;
  }
}
