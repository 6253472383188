// color
$primary-color: #ff3300;
$white-color: #ffffff;
$red-color: #cf242b;
$blue-color: #065baa;
$black-color: #2f2f2f;
$gray-color: #cccccc;
$light-gray-color: #ebebeb;

// body
$body-background: #f9f9fa;
// nav bar
$navbar-background: $light-gray-color;
// side bar
$sidebar-background: #000000;
$sidebar-link-active: $red-color;

//
$panel-background: #ffffff;
$panel-title-color: $primary-color;

//
$link-enable: #065baa;
$link-disable: #6c757d;

//
$light-gray: #f2f2f2;
$medium-gray: #aeaeae;
$dark-gray: #4c4e56;
