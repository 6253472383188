@import "../variable.scss";

.admin-car {
    padding: 60px;
 
    .car-data {
        margin-top: 60px;
    }
    .car-video {
        display: flex;
        align-items: center;
        padding: 20px 0;
        label {
            font-weight: 600;
            font-size: 1.2rem;
            white-space: nowrap;
        }
        input {
            width: 100%;
            margin-bottom: 0.5rem;
        }
    }
    .car-technical {
        margin-top: 60px;
        .technical-table {
            width: 100%;
            margin-top: 20px;
            tr {
                > th,
                > td {
                    padding: 10px 20px;
                    border: 1px solid $gray-color;
                    &:first-of-type {
                        width: 20%;
                        min-width: fit-content;
                    }
                    > input {
                        width: 100%;
                        border: unset;
                    }
                    .-color {
                        display: inline-block;
                        width: fit-content;
                        padding: 2px 5px;
                        margin-bottom: 0.5rem;
                        margin-right: 0.5rem;
                        border-radius: 4px;
                        border: 1px solid #ccc;
                        background-color: #065baa20;
                        i {
                            cursor: pointer;
                            color: $red-color;
                            margin-left: 10px;
                        }
                    }
                    .-add-color {
                        input {
                            min-width: 380px;
                            margin-right: 10px;
                            padding: 0.425rem;
                            border: 1px solid $gray-color;
                        }
                        button {
                            border-radius: 5px;
                        }
                    }
                }
                > th {
                    color: $white-color;
                    background-color: $black-color;
                }
            }
        }
    }
}
