@import "~styles/variable.scss";

.sidebar {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    display: block;
    width: 250px;
    color: $white-color;
    background-color: $sidebar-background;
    > .logo {
        display: block;
        width: 200px;
        height: 200px;
        margin: 25px auto;
    }
    > .menu {
        display: block;
        list-style-type: none;
        margin: 0;
        padding: 0;
        > .link {
            display: block;
            > a {
                display: flex;
                width: 100%;
                height: 40px;
                align-items: center;
                color: $white-color;
                padding-left: 30px;
                padding-right: 30px;
            }
            > .active {
                background-color: $sidebar-link-active;
            }
        }
    }
}
