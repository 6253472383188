@import '~styles/variable.scss';

.footer {
  display: flex;
  justify-content: center;
  background-image: url('../../images/img-info.png');
  flex-wrap: wrap;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  .contact-container {
    width: 70%;
    color: $white-color;
    margin: 2em 0;
    > .-content {
      display: flex;
      margin: 3em 0;
      .row {
        margin: 0;
      }
      h4 {
        font-weight: 400;
      }
      p {
        font-weight: 100;
        line-height: 2;
      }
      .detail {
        display: flex;
        border-right: 2px solid $red-color;
        padding-right: 2em;
        .logo {
          align-self: flex-start;
        }
      }
      .contact {
        padding-left: 2em;
        > .-header {
          padding: 0;
          .red-band {
            background-color: $red-color;
            min-width: 20vw;
            h4 {
              padding: 5px 10px;
            }
          }

          img {
            position: absolute;
            right: -2em;
            height: 3em;
            bottom: 0.5em;
            width: auto;
          }
        }
        > .contact-detail,
        .contact-detail-border {
          display: flex;
          padding: 0.5em 0;
        }
        > .contact-detail-border {
          border-bottom: 1px solid $red-color;
          border-top: 1px solid $red-color;
        }
        .logo {
          padding-right: 0;
          margin-right: 1em;
          .fas {
            color: $red-color;
            font-size: 1.5em;
          }
          img {
            object-position: top;
            width: 1.5em;
          }
        }
        .text {
          padding-left: 0;
          p {
            margin: 0;
            line-height: 1.5;
          }
        }
      }
    }
  }
}
.credit {
  width: 100%;
  background-color: $blue-color;
  position: absolute;
  text-align: center;
  > .text {
    font-weight: 100;
    color: $white-color;
    margin: 8px 0;
  }
}

@media (min-width: 0) and (max-width: 991px) {
  .footer {
    .contact-container {
      > .-content {
        display: block;
        .detail {
          padding-right: 0;
          border: 0;
        }
        .contact {
          padding-left: 0;
        }
      }
    }
  }
}
