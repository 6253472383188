@import "~styles/variable.scss";
.home {
    .-slide .carousel .carousel-indicators li {
        background-color: $red-color;
    }

    .article-box {
        padding: 30px;
        background-color: #000000;
        .-slide {
            max-width: 1300px;
            margin-left: auto;
            margin-right: auto;
        }
        .article-review {
            position: relative;
            display: flex;
            margin-right: auto;
            margin-left: auto;
            width: 100%;
            max-width: 1300px;
            height: 450px;
            margin-bottom: 30px;
            overflow: hidden;

            > .-content {
                display: block;
                width: 100%;
                max-width: 800px;
                color: #fff;
                padding-top: 80px;
                padding-right: 300px;
                z-index: 10;
                background-image: linear-gradient(to right, #000 75%, transparent);
                strong {
                    font-size: 3rem;
                    font-weight: 400;
                }
                span {
                    font-size: 2rem;
                }
            }
            > .-img {
                position: absolute;
                top: 1px;
                right: 0;
                bottom: 1px;
                left: 20%;
                display: block;
                // width: calc(100% - 300px);
                // margin-left: -300px;
                img {
                    object-fit: cover;
                }
            }
        }

        .news-article {
            display: block;
            width: 350px;

            > .-img {
                width: 100%;
                height: 250px;
                position: relative;
                > .-more {
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    color: #ffffff;
                    padding: 8px;
                    background-color: #065baa;
                }
                img {
                    object-fit: cover;
                }
            }
            > .-content {
                height: 180px;
                color: #ffffff;
                padding: 1rem;
                overflow: hidden;
                background-color: $black-color;
                > .-title {
                    font-size: 1.2rem;
                }
                > .-desc {
                    overflow: hidden;
                    font-size: 80%;
                    height: 95px;
                }
            }
        }
        &.-white {
            background-color: #fff;
            .article-review > .-content {
                color: #000;
                background-image: linear-gradient(to right, #fff 75%, transparent);
            }
        }
    }
    .car-type {
        background-color: #fff;
        .-slide {
            max-width: 1300px;
            margin-left: auto;
            margin-right: auto;
            .car-box {
                width: 200px;
                margin-left: auto;
                margin-right: auto;
                cursor: pointer;
                &:hover > .-content > .-title {
                    color: #fff;
                    &::before {
                        background-color: #f00;
                    }
                    &::after {
                        background-color: #000;
                    }
                }
                > .-img {
                    height: 200px;
                }
                > .-content > .-title {
                    position: relative;
                    display: block;
                    margin: 10px 0;
                    text-align: center;
                    padding: 10px 20px 20px 20px;
                    z-index: 10;
                    &::before,
                    &::after {
                        content: "";
                        position: absolute;
                        display: block;
                        left: 0;
                        background-color: #fff;
                    }
                    &::before {
                        transform: skewX(160deg);
                        top: 0;
                        bottom: 10px;
                        right: 10px;
                        z-index: -1;
                        transition: all 0.3s;
                    }
                    &::after {
                        transform: skewX(160deg);
                        top: 10px;
                        bottom: 0;
                        right: 0;
                        z-index: -2;
                        transition: all 0.5s;
                    }
                }
            }
        }
    }
    .special_offer {
        background-color: #fff;
        > div {
            display: flex;
            justify-content: center;
            max-width: 1240px;
            margin-left: auto;
            margin-right: auto;
            overflow: hidden;
        }
        .-left {
            width: calc(60% + 250px);
            margin-left: -250px;
        }
        .-right {
            width: 40%;
            padding-top: 80px;
            > .-content {
                max-width: 490px;
                text-align: center;
                padding: 0 20px;
                > .-topic {
                    position: relative;
                    font-size: 1.2rem;
                    padding: 20px;
                    margin-bottom: 30px;
                    &::before,
                    &::after {
                        content: "";
                        position: absolute;
                        left: calc(50% - 40px);
                        width: 80px;
                        height: 1px;
                        background-color: $red-color;
                    }
                    &::before {
                        top: 0;
                    }
                    &::after {
                        bottom: 0;
                    }
                    p {
                        margin-bottom: 0.5rem;
                    }
                }
                > .-form {
                    padding-top: 30px;
                }
            }
        }
    }
    .-service {
        background-color: #00000096;
        > .-title {
            position: relative;
            color: #fff;
            font-size: 1.5rem;
            text-align: center;
            padding-top: 40px;
            margin-bottom: 30px;
            &::after {
                content: "";
                position: absolute;
                bottom: 0;
                left: calc(50% - 40px);
                width: 80px;
                height: 1px;
                background-color: $red-color;
            }
        }
        .news-article .-content {
            height: auto;
            text-align: center;
            background-color: transparent;
            > .-desc {
                height: auto;
            }
        }
        .background-img {
            position: fixed;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            z-index: -1;
            img {
                object-fit: cover;
            }
        }
    }
}

@media screen and (max-width: 1080px) {
    .home {
        .slick-next:before,
        .slick-prev:before {
            color: $red-color;
        }
        .car-type .-slide {
            margin-left: 40px;
            margin-right: 40px;
        }
        .article-box {
            .-slide {
                margin-left: 40px;
                margin-right: 40px;
            }
            .article-review > .-content {
                max-width: 660px;
            }
            &.-white .article-review > .-content {
                background-image: linear-gradient(to right, #fff 55%, transparent);
            }
        }
    }
}

@media screen and (max-width: 780px) {
    .home {
        .article-box {
            .article-review > .-content {
                background-image: linear-gradient(to right, #000 24%, transparent);
            }
            &.-white .article-review > .-content {
                background-image: linear-gradient(to right, #fff 24%, transparent);
            }
            .news-article {
                width: 280px;
                > .-img {
                    height: 200px;
                }
                > .-content {
                    height: 140px;
                }
            }
        }
        .special_offer {
            > div {
                display: block;
            }
            .-left {
                width: calc(100% + 200px);
            }
            .-right {
                width: 100%;
                padding-top: 0;
                padding-bottom: 30px;
                > .-content {
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }
    }
}

@media screen and (max-width: 780px) {
    .home {
        .article-box {
            padding: 0;
            padding-bottom: 30px;
            .-slide {
                margin-left: 30px;
                margin-right: 30px;
            }
            .article-review {
                height: 330px;
                > .-content {
                    padding-left: 20px;
                    padding-right: 0;
                    strong{
                        font-size: 2.5rem;
                    }
                    span{
                        font-size: 2rem;
                    }
                }
            }
        }
        .special_offer .-left {
            width: calc(100% + 250px);
        }
    }
}
