@import '~styles/variable.scss';

.product {
  margin: 1rem 0;

  //Font
  h5 {
    font-weight: 400;
    font-size: 1rem;
    color: $red-color;
    margin-bottom: 0;
  }

  span {
    font-size: 1rem;
    color: $blue-color;

    &#car {
      font-weight: 200;
      color: $black-color;
    }
  }

  //Nav
  .nav-item {
    border-bottom: 1px solid $medium-gray;
  }

  #key5 {
    &.nav-item {
      border-bottom: 0;
    }
  }

  .nav-link {
    padding: 1em 0;
    &.active {
      color: unset;
      background-color: unset;
      border-radius: unset;

      .component {
        padding-right: 1em;
        border-right: 3px solid $red-color;
      }
    }
  }
  //Component
  .component {
    display: flex;
    padding-right: calc(1em + 3px);
    justify-content: space-between;

    > .-img {
      max-width: 40%;
    }

    > .-text {
      text-align: end;
    }
  }

  //Item
  .item {
    display: block;
    text-align: center;
  }
}

.detail {
  &-desc {
    margin: 2em 0;
    > .-header {
      display: flex;
      justify-content: space-between;
      .btn-group {
        display: flex;
        min-width: 40%;
      }
    }

    > .-body,
    .-detail {
      margin: 2em 0;
      font-weight: 200;
      strong {
        font-size: 2rem;
        font-weight: 400;
      }
      span {
        font-weight: 100;
        color: $medium-gray;
      }

      > .video {
        min-height: 25vh;
        height: 60vh;
      }
    }
  }

  &-slider {
    margin-top: 2em;
    //Carousel
    .carousel {
      img {
        height: 50vh;
      }
      &-indicators {
        bottom: -3em;

        > .active {
          background-color: $red-color;
        }
        li {
          background-color: $black-color;
          width: 4em;
          height: 0.5em;
        }
      }
    }
  }
}

@media (min-width: 0) and (max-width: 991px) {
  .detail {
    &-desc {
      margin: 2em 0;
      > .-header {
        display: block;
        justify-content: space-between;
        .btn-group {
          margin: 1em 0;
          width: 100%;
        }
      }
    }
  }
}
