@import '../variable.scss';

.user {
  &-custom-table {
    &-tech {
      margin: 2em 0;
      overflow-x: scroll;
      .react-bs-container {
        &-header {
          min-width: 100vw;

          th {
            background-color: $black-color !important;
            color: $white-color;
            font-weight: 400;
          }
        }
        &-body {
          min-width: 100vw;
          font-weight: 200;
          tr {
            border-bottom: 1px solid $light-gray;
          }
          td {
            vertical-align: baseline;
            border-top: unset;
            white-space: initial;
          }
        }
      }
    }
  }
  &-compare-table {
    overflow-x: scroll;
    margin: 2em 0;
    .time-out {
      display: none;
    }
    #row_1 {
      &.row {
        background: $black-color;
        color: $white-color;
      }
    }
    #row_2 {
      font-weight: 200;
      &.row {
        background: $light-gray;
        color: $black-color;
      }
    }
    #row_3 {
      font-weight: 200;
      &.row {
        background: $light-gray-color;
        color: $black-color;
      }
    }
    .row {
      background: $light-gray;
      margin: 0;
      img {
        width: fit-content;
        > &.header-img {
          width: fit-content;
          padding-bottom: 1em;
        }
      }
    }
    #last-col1 {
      &.col {
        border: unset;
      }
    }
    .col {
      border-right: 1px solid lightgray;
      padding: 1em;
      img {
        width: fit-content !important;
      }
      > .select {
        display: flex;
        flex-direction: column;
        align-items: center;
        .header-img {
          width: -webkit-fill-available !important;
          padding-bottom: 1em;
        }
      }
    }
  }
  &-down-panel {
    margin: 2em 0;
    .input {
      &-row {
        margin-bottom: 2em;
        border-bottom: 1px solid $light-gray;
      }
      &-col {
        align-self: flex-end;
        > .-price {
          padding-bottom: 1em;
          > .-text {
            font-weight: 200;
          }
        }
        .btn {
          margin-bottom: 1rem;
          width: 100%;
        }
      }
    }
    > .-down-total {
      margin: 0 15%;
      > .-title {
        text-align: center;
        justify-content: center;
        margin-bottom: 1em;
        background-color: $white-color;
      }
      .total {
        &-row {
          background-color: $light-gray;
          padding: 0 1.5em;
          span {
            font-weight: 400;
            font-size: 1.25em;
          }
          .red-text {
            color: $red-color;
            padding-right: 1em;
          }
          > .-detail,
          .-detail-total {
            border-bottom: 0.5px solid $medium-gray;
            padding: 1em 0;
            &-total {
              text-align: end;
            }
          }
          .-detail-total-last {
            border: 0;
            padding: 1em 0;
          }
        }
      }
    }
  }
  &-pop-up {
    display: none;
  }
  &-modal {
    width: 100%;
    margin: 2em;
  }
  &-contact {
    display: flex;
    justify-content: center;
    background-image: url('../../images/img-info.png');
    flex-wrap: wrap;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    .contact-container {
      width: 70%;
      color: $white-color;
      margin: 2em 0;
    }
  }
}

@media (min-width: 0) and (max-width: 991px) {
  .user {
    &-compare-table {
      .row {
        min-width: 50vw;
        width: 500vw;
      }
    }
    &-down-panel {
      > .-down-total {
        margin: 0;

        .total-row {
          span {
            font-size: 1em;
          }
        }
      }
    }
    &-pop-up {
      position: absolute;
      z-index: 1;
      margin: -1.25em;
      display: flex;
      flex-direction: column;
      align-items: center;
      background: #fffa;
      height: -webkit-fill-available;
      width: -webkit-fill-available;
      h3 {
        margin: 2em;
        text-align: center;
      }
      img {
        width: 50%;
      }
    }
    &-contact {
      height: max-content;

      .contact-container {
        width: 80%;
        margin: 2em 0;
      }
    }
  }
}
