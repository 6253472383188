@import '~styles/variable.scss';

.navbar-top {
  display: flex;
  height: 70px;
  align-items: center;
  justify-content: space-around;
  background-color: $white-color;
  font-weight: 200;
  > .-lefttop {
    display: inline-flex;
    width: 50%;
    justify-content: center;

    > .logotop {
      display: block;
      width: 100px;
      height: 100px;
    }
  }

  > .-menu-sm {
    display: none;
    @media (min-width: 0) and (max-width: 991px) {
      display: flex;
      flex-direction: column;
      position: absolute;
      width: 100%;
      height: 1em;
      z-index: 12;
      .menu {
        height: auto;
        margin-top: 2.4em;
        background-color: $red-color;
        list-style-type: none;
        justify-content: center;
        align-items: center;

        > .link {
          display: block;
          height: 100%;
          > a {
            display: flex;
            height: 100%;
            align-items: center;
            padding: 1em;
            color: $white-color;
            text-decoration: none;

            &.link-active {
              background-color: $white-color;
              color: $black-color;
              font-weight: 300;
            }
          }
        }
      }

      //   .drop-menu {
      //     height: 0px;
      //     transition: height 7s ease;
      //     color: salmon;
      //     &-active {
      //       height: 100px;
      //       //   transition: height 7s ease;
      //       color: seagreen;
      //     }
      //   }
    }
  }

  .navbar-car {
    display: flex;
    height: 150px;
    align-items: center;
    justify-content: space-around;
    background-color: #fff;
  }

  @media (min-width: 0) and (max-width: 991px) {
    border-bottom: 7px solid $black-color;
  }
}
.-righttop {
  display: flex;
  width: 50%;
  align-items: baseline;

  @media (min-width: 0) and (max-width: 991px) {
    display: none;
  }
}
.icon {
  width: 24px;
  height: 17px;
  margin: 0 10px;
  align-items: center;
}
.icon-facebook {
  width: 34px;
  height: 34px;
  margin: 0 10px;
  align-items: center;
  cursor: pointer;
}

.navbar {
  display: flex;
  height: 70px;
  align-items: center;
  justify-content: space-between;
  background-color: $red-color;
  font-weight: 100;
  padding: 0;
  > .-left {
    display: inline-flex;
    width: 100%;
    height: 100%;

    > .logo {
      display: block;
      width: 50px;
      height: 50px;
    }
    > .menu {
      display: flex;
      list-style-type: none;
      margin: 0;
      padding: 0;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
      > .link {
        display: block;
        height: 100%;
        > a {
          display: flex;
          height: 100%;
          align-items: center;
          padding-left: 30px;
          padding-right: 30px;
          color: $white-color;
          text-decoration: none;

          &.link-active {
            background-color: $white-color;
            color: $black-color;
            font-weight: 300;
          }
        }
      }
    }
  }
  @media (min-width: 0) and (max-width: 991px) {
    display: none;
  }
}
.nav-black {
  display: block;
  background-color: $sidebar-background;
  width: 100%;
}
